<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading pl-3">Employee view</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5> </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 align-self-center fill-height pa-4>
            <v-card tile flat>
              <v-layout wrap justify-center py-4>
                <v-flex xs12 align-self-start text-left pa-3>
                  <span class="itemHeading2">Name :</span> &nbsp;
                  <span>{{ userData.eid.name }}</span>
                </v-flex>
                <v-flex xs12 text-left pa-3>
                  <span class="itemHeading2">Email :</span> &nbsp;
                  <span> {{ userData.eid.email }}</span>
                </v-flex>
                <v-flex xs12 text-left pa-3>
                  <span class="itemHeading2">Phone :</span> &nbsp;
                  <span> {{ userData.eid.mobile }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-divider > </v-divider>
                </v-flex>
                <v-flex xs12 v-if="documentList.length < 1" pt-5>
                    <span class="RR">  No Document Uploads....!  </span>
                </v-flex>
                <v-flex v-else
                  xs12
                  pa-3
                  text-left
                  v-for="(item, i) in documentList"
                  :key="i"
                >
                  <v-layout wrap>
                    <v-flex xs12>
                      <span class="RR uLine">Document Title :</span> &nbsp;&nbsp;&nbsp;
                      <span class="itemHeading2"> {{ item.onboardid.title }}</span>

                    </v-flex>
                    <!-- <v-flex xs12 pt-3>
                      <span class="itemHeading2"> {{ item.onboardid.title }}</span>
                    </v-flex> -->
                    <v-flex xs12 pt-3>
                      <span class="RR uLine2">Uploads </span>
                    </v-flex>
                    <v-flex xs12 pt-3 v-if="item.filename.length > 0 ">
                      <v-chip
                        v-for="(val, i) in item.filename"
                        :key="i"
                        style="margin-right: 10px; margin-bottom: 10px"
                      >
                        <a :href="'https://wtiadmin.in/i/' + val">
                          <span> {{ val }} </span>
                        </a>
                        &nbsp;
                        <v-icon small> mdi-download</v-icon>
                      </v-chip>
                    </v-flex>
                   <v-flex xs12 v-else pt-4>
                      <span style="color:red" class="RR"> No document uploads Yet ...!</span>
                   </v-flex>
                    <v-flex xs12 pt-3>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-flex xs12>
                   <span
                  v-if="
                    item.filename[0].split('.').pop() == 'pdf' ||
                    item.filename[0].split('.').pop() == 'xlsx' ||
                    item.filename[0].split('.').pop() == 'doc' ||
                    item.filename[0].split('.').pop() == 'docx'
                  "
                >
                  <a
                   
                    :href="baseURL + '/i/' +item.filename[0]"
                  >
                    <v-btn  dark color="blue" small> View </v-btn></a
                  ></span
                >

                </v-flex> -->
                <!-- </v-layout>
                <v-layout wrap justify-center py-4> -->
                <!-- <v-flex xs12 sm6 lg6 pa-2>
                  <v-btn
                    color="success"
                    style="font-family: poppinsmedium"
                    small
                    block
                    @click="editcat(documentList)"
                    >Edit</v-btn
                  >
                </v-flex> -->
                <!-- <v-flex xs12 sm6 lg6 pa-2>
                  <v-dialog
                    persistent
                    v-model="documentList.delete"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        dark
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this Job
                        Title?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="documentList.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="
                            deleteItem(documentList),
                              (documentList.delete = false)
                          "
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card tile>
              <v-card-title>
                <span class="headline">Edit Document</span>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 pt-5 text-left>
                    <v-text-field
                      v-model="editingitem.title"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 align-self-center text-left pt-5>
                    <!-- <v-select
                      v-model="editingitem.jobCategory"
                      :items="categoryList"
                      item-text="name"
                      item-value="_id"
                      item-color="#8d8d8d"
                      color="#8d8d8d"
                      outlined
                      dense
                      label="Job Category"
                    >
                    </v-select> -->
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pt-5>
                    <span>Content</span>
                    <br />
                    <vue-editor
                      class="textField2 pt-1"
                      v-model="editingitem.description"
                    ></vue-editor>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pt-5>
                    <!-- <v-text-field
                      v-model="editingitem.location"
                      label="Location"
                      :rules="[rules.required]"
                      required
                      outlined
                      dense
                    ></v-text-field> -->
                  </v-flex>
                  <v-flex xs12 pt-6>
                    <v-icon x-large color="#408BFF" @click="$refs.files.click()"
                      >mdi-cloud-upload</v-icon
                    >
                  </v-flex>
                  <v-flex xs12 pb-5>
                    <span class="con4">upload Document</span>
                    &nbsp;
                    <input
                      v-show="false"
                      id="file1"
                      ref="files"
                      type="file"
                      multiple
                      @change="browseCover"
                    />
                  </v-flex>
                  <v-flex xs12 py-3 text-left>
                    <v-chips v-for="(item, i) in documentFile1" :key="i">
                      <v-chip
                        @click="documentFile1.splice(i, 1)"
                        close
                        dark
                        color="green"
                        style="margin-right: 6px; margin-bottom: 10px"
                      >
                        {{ item.name }}
                      </v-chip>
                    </v-chips>
                    <v-chips v-for="(item, i) in editingitem.filename" :key="i">
                      <v-chip
                        @click="
                          deletePDF(item, editingitem._id),
                            editingitem.filename.splice(i, 1)
                        "
                        close
                        dark
                        color="green"
                        style="margin-right: 6px; margin-bottom: 10px"
                      >
                        {{ item }}
                      </v-chip>
                    </v-chips>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  v-if="!g"
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      userData: {},
      //
      appLoading: false,
      documentList: [],
      title: null,
      documentFile1: [],
      documentFile: null,
      documentID: null,
      docsdata: new FormData(),
      g: null,
      //end
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },

      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  

  mounted() {
    this.getData();
    // this.getCategory()
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // jobCategory() {
    //   this.getCategory()
    // }
  },

  //   created() {
  //     this.initialize();
  //   },

  methods: {
    addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/admin/add/edit/document",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            title: this.title,
            description: this.description,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log();
            if (this.documentID != null) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.showsnackbar = true;
        this.msg = "Please Select Document ";
      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/admin/upload/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          (this.title = null),
            (this.content = null),
            (this.showsnackbar = true);
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },

    browseCover1(event) {
      var got = event.target.files[0];
      console.log("result is", got);
      this.documentFile = got;

      //   if (got.type == "application/pdf") {
      //     this.coverFile = got;
      //   } else {
      //     var img;
      //     img = new Image();
      //     img.src = window.URL.createObjectURL(event.target.files[0]);
      //     var ty = event.target.files[0];
      //     console.log(ty);
      //     if (ty.size > 3145728) {
      //       document.getElementById("file1").value = [];
      //       this.msg = "File size Should be less than 3MB";
      //       this.showSnackBar = true;
      //       return;
      //     } else {
      //       this.coverFile = got;
      //     }
      //   }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/eachemployee",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          empid: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.documentList = response.data.data;
            this.userData = response.data.user;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    deleteItem(val) {
      this.dialogDelete = false;
      this.appLoading = true;
      axios({
        url: "/admin/remove/data",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: val._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.getData();
            this.get;
            this.$router.go(-1);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    editcat(items) {
      this.editdialog = true;
      this.editingitem = items;
    },
    deletePDF(name, id) {
      this.appLoading = true;
      axios({
        url: "/admin/remove/uploaddocument",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          document: name,
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;

          this.showsnackbar = true;
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit(id) {
      this.appLoading = true;
      axios({
        url: "/admin/add/edit/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
          title: this.editingitem.title,
          description: this.editingitem.description,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.documentID = response.data.data._id;
          this.editdialog = false;
          if (this.documentID != null) {
            this.docsupload();
          } else {
            alert("document updated");
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style >
.uLine {
  border-bottom: 3px solid #005f32;
}
.uLine2 {
  border-bottom: 3px solid green;
}

</style>